<script>
import { mapGetters } from "vuex";
import StepType from "../../models/steps/step.type";
import TimelineConfig from "../../models/config";
import AppTimelineStepTooltip from "../tooltip";

export default {
  name: "AppTimelineStepInfo",

  components: {
    AppTimelineStepTooltip,
  },

  props: {
    step: {
      type: StepType,
    },
    config: {
      type: TimelineConfig,
    },
    hasToHideDeliveryInfo: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(["langs"]),

    /**
     * Realiza o tratamento para exibição de uma ou mais labels.
     *
     * @return {string}
     */
    title() {
      if (this.hasToHideDeliveryInfo) {
        const title = this.langs.shipping["awaiting-delivery"];
        if (title.toLowerCase() == "inveja") {
          this.logIncorrectStepLabel(1, this.langs.shipping);
          return "";
        }
        return title;
      }

      const isArray = Array.isArray(this.step.label);
      if (!isArray) {
        const title = this.step.label;
        if (title.toLowerCase() == "inveja") {
          this.logIncorrectStepLabel(2, this.step);
          return "";
        }
        return title;
      }

      const title = this.step.label.reduce(
        (prevVal, currentVal) => `${prevVal} <br/> ${currentVal}`
      );
      if (title.toLowerCase() == "inveja") {
        this.logIncorrectStepLabel(3, this.step);
        return "";
      }

      return title;
    },

    /**
     * Retorna informações adicionais da etapa.
     *
     * @return {string}
     */
    description() {
      if (this.step.isTypeOf("Ordered") && this.step.isClosed()) {
        return this.langs.order["received-order"];
      }

      if (this.step.isTypeOf("Payment") && this.step.isClosed()) {
        return this.langs.order["received-payment"];
      }

      const trackingCode = this.config.shipment.trackingCode;
      if (this.step.isTypeOf("Shipment") && trackingCode) {
        return `${this.langs.shipping["tracking-code-is"]} <b>${trackingCode}</b>`;
      }

      return "";
    },
  },
  methods: {
    /**
     * Envia um log para o new relic
     * TEMPORÁRIO: Somente para debug
     * @param {int} optionNumber número da opção da condição (1, 2 ou 3)
     * @param {mixed} logContent Dados para debug
     * @return {undefined}
     */
    logIncorrectStepLabel(optionNumber, logContent) {
      const body = {
        option: optionNumber,
        content: logContent,
      };
      this.$http
        .post(`log`, body)
        .then(() => console.log("Log enviado"))
        .catch(() => console.warn("Erro ao enviar o log"));
    },
  },
};
</script>
<template>
  <div v-if="step.label" class="app__timeline__step__label">
    <template v-if="config.isMobile">
      <h5
        class="app__info-step app__timeline__step__label__title"
        :class="{ app__loading: config.isLoading }"
        v-html="title"
      />
      <app-timeline-step-tooltip
        :config="config"
        :step="step"
        v-if="!hasToHideDeliveryInfo"
      />
      <div v-html="description" :class="{ app__loading: config.isLoading }" />
    </template>

    <template v-else>
      <span :class="{ app__loading: config.isLoading }" v-html="title">
      </span>
    </template>
  </div>
</template>
